import { uniqBy, findIndex, findLastIndex, remove, filter, size, cloneDeep } from 'lodash';

export const retainActivitiesTransit = ({ retainTransit, retainActivities, itinerarySegments, segmentsOriginal }) => {
  // Runs through itinerarySegments (for ui) and segmentsOriginal (from db) and grabs any comps.
  // accommodation: a->b->c->a->b->c would look for eg: 1st b->c or 2nd a->b
  // activity: a->b->a would look for eg: 2nd a
  // (entry, accommodation1] + n(accommodation1->accommodation2, transit pre accommodation2, activities post accommodation2) + [,exit).

  let transitSegments; // transitActivitiesSegments

  if (retainTransit || retainActivities) {
    let accommodation1 = 0,
      accommodation2 = 1;
    transitSegments = itinerarySegments.flatMap((segment, idx) => {
      let segmentsToPush = [],
        segmentsTemp = [];

      if (idx === 0) {
        // Check entry->accommodation (entry->accommodation1).
        let supplier1 = segment.supplierCode ?? segment.locationCode;
        accommodation1 = findIndex(segmentsOriginal, { type: 'stay' }); // First stay.
        if ([segmentsOriginal[accommodation1].supplierCode, segmentsOriginal[accommodation1].locationCode].includes(supplier1)) {
          segmentsTemp = segmentsOriginal.slice(1, accommodation1); // Slice from `entry`.
          if (!retainActivities) {
            remove(segmentsTemp, { type: 'service' });
          }
          if (!retainTransit) {
            remove(segmentsTemp, segment => segment.type !== 'service');
          }
          segmentsToPush.push(...segmentsTemp, segment);
        }
      }

      if (idx > 0) {
        // Check ordinal (first, second, ...) accommodation->accommodation.
        let supplier1 = itinerarySegments[idx - 1].supplierCode ?? itinerarySegments[idx - 1].locationCode;
        let supplier2 = segment.supplierCode ?? segment.locationCode; // itinerarySegments.idx
        let idx1 = 0,
          idx2 = 0,
          idxq = 0;
        let ordinal = 0;

        if (retainTransit) {
          while (idx2 < idx && idx1 !== -1) {
            idx1 = findIndex(itinerarySegments, is => [is.supplierCode, is.locationCode].includes(supplier1), idx2);
            idx2 = idx1 + 1;
            if (idx2 !== 0 && [itinerarySegments[idx2]?.supplierCode, itinerarySegments[idx2]?.locationCode].includes(supplier2)) {
              ordinal++;
            }
          }
          idx1 = idx2 = 0;
          while (idx1 !== -1 && idx2 !== -1 && idx1 < size(segmentsOriginal) && idx2 < size(segmentsOriginal) && ordinal > 0) {
            idx1 = findIndex(segmentsOriginal, so => so.type === 'stay' && [so.supplierCode, so.locationCode].includes(supplier1), idx2);
            idx2 = findIndex(segmentsOriginal, so => so.type === 'stay' && [so.supplierCode, so.locationCode].includes(supplier2), idx1);
            idxq = findIndex(segmentsOriginal, { type: 'stay' }, idx1 + 1); // idx1->idx[2q] : idx1->idxq->idx2 :: yay : nay
            if (idx1 !== -1 && idx2 !== -1 && idxq === idx2) {
              ordinal--;
              accommodation1 = idx1 + 1;
              accommodation2 = idx2;
            }
          }
          if (!ordinal) {
            segmentsTemp = segmentsOriginal.slice(accommodation1, accommodation2);
            if (!retainActivities) {
              remove(segmentsTemp, { type: 'service' });
            }
            if (0)
              if (!retainTransit) {
                remove(segmentsTemp, segment => segment.type !== 'service');
              }
            segmentsToPush.push(...segmentsTemp);
          }
        }

        if (retainActivities) {
          idx1 = ordinal = 0;
          while (idx1 < idx) {
            if ([itinerarySegments[idx1].supplierCode, itinerarySegments[idx1].locationCode].includes(supplier1)) {
              ordinal++;
            }
            idx1++;
          }
          idx1 = 0;
          while (ordinal > 0) {
            idx1 = findIndex(
              segmentsOriginal,
              so => so.type === 'stay' && [so.supplierCode, so.locationCode].includes(supplier1),
              idx1 + 1,
            );
            if (idx1 !== -1) {
              ordinal--;
              accommodation1 = idx1 + 1;
              accommodation2 = findIndex(segmentsOriginal, { type: 'stay' }, idx1 + 1);
            }
          }
          if (!ordinal) {
            segmentsTemp = segmentsOriginal.slice(accommodation1, accommodation2);
            segmentsTemp = filter(segmentsTemp, { type: 'service' }); // type:service only.

            if (0)
              if (!retainActivities) {
                remove(segmentsTemp, { type: 'service' });
              }
            segmentsToPush.push(...segmentsTemp);
          }
        }

        segmentsToPush.push(segment); // N
      }

      if (idx === size(itinerarySegments) - 1) {
        // Check accommodation->exit.
        let supplier1 = segment.supplierCode ?? segment.locationCode;
        accommodation1 = findLastIndex(segmentsOriginal, { type: 'stay' });
        if ([segmentsOriginal[accommodation1].supplierCode, segmentsOriginal[accommodation1].locationCode].includes(supplier1)) {
          segmentsTemp = segmentsOriginal.slice(accommodation1 + 1, -1); // Slice till `exit`.
          if (!retainActivities) {
            remove(segmentsTemp, { type: 'service' });
          }
          if (!retainTransit) {
            remove(segmentsTemp, segment => segment.type !== 'service');
          }
          segmentsToPush.push(...segmentsTemp);
        }
      }

      remove(segmentsToPush, segment => segment.sequence >= 100); // Remove dmc lines.
      segmentsToPush = uniqBy(segmentsToPush, 'id'); // Remove activityPost running into transitPre.
      return size(segmentsToPush) ? segmentsToPush : segment;
    });
  }

  transitSegments = cloneDeep(transitSegments);
  transitSegments?.map(segment => delete segment.currency); // Trick reprice.

  let idx = 0;
  let segment, nights;
  while (idx < size(transitSegments)) {
    // Remove activities if nights decrease.
    segment = transitSegments[idx];
    if (segment.nights > 0) {
      nights = segment.nights;
    }

    if (nights <= segment.day + 1) {
      // 2 nights => 3 days.
      transitSegments.splice(idx, 1);
      continue;
    }

    idx++;
  }

  idx = 0;
  if (retainTransit)
    while (idx < size(transitSegments)) {
      // No auto travel when we choose transit.
      segment = transitSegments[idx];
      if (!['service'].includes(segment.type)) {
        //segment.overrideAutoBookTravel = true;
      }
      idx++;
    }

  return transitSegments;
};
