import React, { useMemo } from 'react';
import { differenceInCalendarDays, isBefore, isAfter, eachDayOfInterval, addDays } from 'date-fns';
import makeStyles from '@mui/styles/makeStyles';
import { filter, size, set, get, reduce, cloneDeep, head, last, some, uniq, values } from 'lodash';
import clsx from 'clsx';
import { noop } from 'common';
import NiceModal from '@ebay/nice-modal-react';

import { useSegments, useSegmentsOriginal } from './hooks';
import { useQuoteOnly } from './hooks';
import { useGetQuoteMutation, useCalendarBySupplierWithRoomsMulti } from './queries';
import { useBuilderStore } from './store';
import { useUser } from 'common/hooks/user';
import { useRates } from 'common/context/Customizations';

import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import ErrorIcon from '@mui/icons-material/Error';

import { CALENDAR_STATE, OWN_ACCOMMODATION_SUPPLIER_CODE } from 'itrvl-types';
import { Button, CircularProgress } from '@mui/material';
import { formatDate, parseDate } from './utils';
import Tooltip from 'components/v2/Tooltip';
import ConfirmDenyModal from 'components/v2/Modals/ConfirmDenyModal';

import { Grid } from '@mui/material';
import sharedStyles from 'styles/shared';
import CheckboxGroup from 'components/v2/FormElements/CheckboxGroupRhf';
import { useForm } from 'react-hook-form';
import { retainActivitiesTransit } from './CopyToBuilderUtils';

import logger from 'itrvl-logger';
const log = logger(__filename);
log.trace(__filename);

const useStyles = makeStyles(theme => ({
  '@global': {
    '& .baseModalSubtext': {
      display: 'block',
    },
    '& .formGroup': {
      flexDirection: 'column',
    },
  },
  btn: {
    display: 'flex',
    gap: 8,
    textTransform: 'uppercase',
    height: '100%',
    fontWeight: 400,
    lineHeight: 1,
    fontSize: 12,
  },
  wrapper: {
    height: '100%',
  },
  disabled: {
    cursor: 'not-allowed',
  },
  loader: {
    color: '#fff',
  },
  error: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    '& svg': {
      color: theme.palette.secondary.main,
      width: 16,
    },
  },
}));

const paxToDefaultRooms = (adults, childrenAges) => {
  let adultsLeft = adults;
  let childrenAgesLeft = cloneDeep(childrenAges);
  let rooms = [];
  while (childrenAgesLeft.length > 0) {
    let children = childrenAgesLeft.length > 1 ? [childrenAgesLeft.shift(), childrenAgesLeft.shift()] : [childrenAgesLeft.shift()];
    let room = { roomType: 'fr', adults: 1, children };
    adultsLeft--;
    rooms.push(room);
  }
  while (adultsLeft > 0) {
    let room = { roomType: adultsLeft > 1 ? 'dr' : 'sr', adults: adultsLeft > 1 ? 2 : 1, children: [] };
    rooms.push(room);
    adultsLeft -= 2;
  }
  return rooms;
};

const GetQuoteButton = () => {
  const s = sharedStyles();
  const classes = useStyles();
  const user = useUser();
  const rates = useRates();
  const segments = useSegments();
  const segmentsOriginal = useSegmentsOriginal();
  const hasSegments = size(segments) > 0;
  const adults = useBuilderStore(state => state.data.adults);
  const children = useBuilderStore(state => state.data.children);
  const childrenAges = cloneDeep(useBuilderStore(state => state.data.childrenAges));
  const minTripChildAge = children > 0 && childrenAges ? Math.min(...childrenAges) : undefined;
  const defaultRooms = paxToDefaultRooms(adults, childrenAges);

  const mutation = useGetQuoteMutation();
  const loading = mutation.isLoading;

  const { control, setValue, getValues } = useForm({
    defaultValues: { retainActivities: false, retainTransit: false },
  });

  const retainOptions = [
    { label: 'Retain Activities', value: 'retainActivities' },
    { label: 'Retain Transit', value: 'retainTransit' },
  ];

  const isQuoteOnly = useQuoteOnly();
  const querySpecificAvailabilityMulti = useCalendarBySupplierWithRoomsMulti(segments, isQuoteOnly);

  const onClickPre = () => {
    if (!segmentsOriginal) {
      onClick();
    } else {
      // setValue on show so that by component<=>form has getValues of false.
      setValue('retainActivities', true);
      setValue('retainTransit', true);
      NiceModal.show(ConfirmDenyModal, {
        title: 'Updated quote confirmation',
        subtext:
          //'You will receive a new quote number. Please note that some details, including activities, presentation edits, and custom transit, may be lost. Your original quote will be retained for reference to itinerary details.',
          [
            'You will receive a new booking reference number in "Quote" status. Any provisionally held inventory will need to be re-requested or transferred to the new booking.' /*,'Would you like to retain details from your original quote? Please note that some transit and activities may be lost depending on the updated accommodation locations and nights.'*/,
          ],
        fullWidth: true,
        children: (
          <Grid item xs={12}>
            <CheckboxGroup
              control={control}
              options={retainOptions}
              label="Would you like to retain details from your original quote? Please note that some transit and activities may be lost depending on the updated accommodation locations and nights."
            />
            <hr className={s.hr} />
          </Grid>
        ),
        maxWidth: 'sm',
        showControls: true,
        showHide: true,
        disableHide: false,
        denyText: 'back',
        confirmText: 'confirm',
        onConfirm: modal => {
          onClick();
          modal.hide();
        },
      });
    }
  };

  const onClick = async () => {
    let accommodationRooms = {};
    const itinerary = {
      clientId: get(user, 'client.id'),
      name: 'Test Itinerary',
      itinerary: {
        adults,
        children,
        pax: adults + children,
        params: {
          rooms: defaultRooms,
          client: get(user, 'client'),
          customizations: {
            video: [{ isExcluded: true, supplierCode: OWN_ACCOMMODATION_SUPPLIER_CODE }],
          },
        },
        state: 'estimate',
      },
    };

    if (hasSegments) {
      let startDate = segments[0].startDate;
      let endDate = segments[0].endDate;
      let videoCustomizations = [];

      const itinerarySegments = segments.map((segment, idx) => {
        let segmentEndDate = addDays(parseDate(segment.endDateString), 1);

        let childrenAgesLeft = cloneDeep(childrenAges);
        if (isAfter(segmentEndDate, endDate)) {
          endDate = segmentEndDate;
        }
        if (isBefore(segment.startDate, startDate)) {
          startDate = segment.startDate;
        }
        let roomInfo = reduce(
          segment.rooms,
          (acc, room) => {
            let thisRoom = {
              roomType: '',
              adults: 0,
              children: [],
            };
            thisRoom.adults = room.adults;
            for (let numChildren = 0; numChildren < room.children; numChildren++) {
              thisRoom.children.push(childrenAgesLeft.shift());
            }
            switch (room?.room?.systemType) {
              case 'Twin':
                thisRoom.roomType = 'tr';
                break;
              case '_Double':
                thisRoom.roomType = 'dr';
                break;
              case 'Family':
                thisRoom.roomType = 'fr';
                break;
              case 'Single':
                thisRoom.roomType = 'sr';
                break;
              default:
                log.error('unknown room systemType: ', room);
                break;
            }
            if (thisRoom.adults === 1 && thisRoom.children.length === 0) {
              thisRoom.roomType = 'sr';
            }
            acc.rooms.push(thisRoom);
            acc.roomTypeIds.push(room.room.roomTypeId);
            acc.optionKeys.push(room.room.optionKey);
            acc.systemTypes.push(room.room.systemType);
            return acc;
          },
          { rooms: [], roomTypeIds: [], optionKeys: [], systemTypes: [] },
        );

        let staySegment = {
          type: 'stay',
          optionKeys: roomInfo.optionKeys,
          roomTypeIds: roomInfo.roomTypeIds,
          systemTypes: roomInfo.systemTypes,
          startDate: formatDate(segment.startDate),
          endDate: formatDate(segmentEndDate),
          nights: differenceInCalendarDays(segmentEndDate, segment.startDate),
          supplierCode: segment.supplierCode,
        };
        if (segment.supplierCode === OWN_ACCOMMODATION_SUPPLIER_CODE) {
          videoCustomizations[idx] = { isExcluded: true, supplierCode: '' };
          delete staySegment.supplierCode;
          staySegment.arrangedBy = 'Agent';
          staySegment.locationCode = segment.regionCode || 'ZZZ';
          staySegment.description = segment.title || 'Own Arranged Accommodation';
        }
        if (segment.dmcArrangedOnly) {
          staySegment.arrangedBy = 'DMC';
        }
        if (roomInfo.rooms.length === 0) {
          set(accommodationRooms, segment.supplierCode, defaultRooms);
        } else {
          set(accommodationRooms, segment.supplierCode, roomInfo.rooms);
        }

        return staySegment;
      }, []);
      if (videoCustomizations.length > 0) {
        videoCustomizations = videoCustomizations.map(el => el || {});
        itinerary.itinerary.params.customizations.video = videoCustomizations;
      }
      itinerary.itinerary.startDate = itinerary.startDate = formatDate(startDate);
      itinerary.itinerary.endDate = itinerary.endDate = formatDate(endDate);

      let transitSegments; // transitActivitiesSegments
      transitSegments = retainActivitiesTransit({
        retainTransit: getValues('retainTransit'),
        retainActivities: getValues('retainActivities'),
        itinerarySegments,
        segmentsOriginal,
      });

      let mergedSegments = transitSegments ? [...transitSegments] : [...itinerarySegments];

      //console.log({ segmentsOriginal, mergedSegments }); // eslint-disable-line no-console
      //return; // TODO

      if (0) {
        // TODO copy over activities/transit/PE from og itinerary.
        log.trace({ segmentsOriginal });
        // itinerarySegments and segmentsOriginal

        // Activities (maybe re-uuid?)
        // ----------
        // cp segmentI (drop days?)

        // Transit (maybe re-uuid?)
        // -------
        // entry->segment (add entry?)
        // segmentI->segmentJ
        // segment->exit (add exit?)

        // Both
        // ----
        // delete seg.currency; // Trick reprice.

        // PE
        // --
        // async api call to PE.copy(og, new).
        // Make sure new uuid and such bring over render data.
      }
      itinerary.itinerary.segments = mergedSegments;
      itinerary.itinerary.params.accommodationRooms = accommodationRooms;
      itinerary.name = `${uniq(segments.map(segment => segment.country)).join(', ')} Adventure`;

      log.debug('itinerary: ', itinerary);
    }
    itinerary.rates = rates;
    mutation.mutate(itinerary);
  };

  const tripAdults = useBuilderStore(state => state.data.adults);
  const tripChildren = useBuilderStore(state => state.data.children);
  const dateMap = useBuilderStore(state => state.ui.dateMap);
  const client = useBuilderStore(state => state.data.client);

  const errors = useMemo(() => {
    const errors = [];

    if (querySpecificAvailabilityMulti.length) {
      const samIsFetching = some(querySpecificAvailabilityMulti, 'isFetching');
      const samRequested =
        !samIsFetching &&
        querySpecificAvailabilityMulti.every(query => {
          return values(query?.data).every(date =>
            [CALENDAR_STATE.AVAILABLE_ON_REQUEST, CALENDAR_STATE.AVAILABLE, CALENDAR_STATE.AVAILABLE_ON_REQUEST_CHECK].includes(date.s),
          );
        });
      if (samIsFetching) errors.push('Fetching specific availability');
      if (!samRequested) errors.push('Unfortunately, the rooms highlighted in your selection are not available');
    }

    if (tripAdults + tripChildren === 0) {
      errors.push('No travelers have been assigned to the itinerary');
    }

    if (tripAdults === 0 && tripChildren > 0) {
      errors.push('An adult must be present if you have children');
    }

    const ownAccommodationSegments = filter(segments, segment => segment.supplierCode === OWN_ACCOMMODATION_SUPPLIER_CODE);
    if (some(ownAccommodationSegments, segment => !segment.regionCode)) {
      errors.push('Not all own arranged accommodations have a region selected');
    }
    const nonOwnAccommodationSegments = filter(segments, segment => segment.supplierCode !== OWN_ACCOMMODATION_SUPPLIER_CODE);
    if (
      some(nonOwnAccommodationSegments, segment => {
        if (segment.dmcArrangedOnly === true || segment.consultantInteractionRequired === true) {
          return;
        }
        const { adults, children } = reduce(
          segment?.rooms,
          (totals, room) => {
            totals.adults += room.adults || 0;
            totals.children += room.children || 0;
            return totals;
          },
          {
            adults: 0,
            children: 0,
          },
        );
        return adults !== tripAdults || children !== tripChildren;
      })
    ) {
      errors.push('Not all travelers have been assigned to rooms');
    }

    if (
      some(nonOwnAccommodationSegments, segment => {
        if (segment.minChildAge && segment.minChildAge > 0 && minTripChildAge && minTripChildAge < segment.minChildAge) {
          return true;
        }
      })
    ) {
      errors.push(`Not all accommodations can accept children aged ${minTripChildAge} years`);
    }

    const start = head(segments);
    const end = last(segments);

    if (start?.startDate && end?.endDate) {
      const hasGap = eachDayOfInterval({
        start: start.startDate,
        end: end.endDate,
      }).some(date => {
        const dateString = formatDate(date);
        return !(dateString in dateMap);
      });

      if (hasGap) {
        errors.push('There is a gap in the itinerary dates');
      }
    }

    if (!client) {
      errors.push('No client is selected');
    }

    return errors;
  }, [segments, dateMap, tripAdults, tripChildren, minTripChildAge, client, querySpecificAvailabilityMulti]);

  const disabled = size(errors) > 0;

  return (
    hasSegments && (
      <Tooltip
        title={
          disabled ? (
            <>
              {errors.map((error, index) => (
                <div className={classes.error} key={index}>
                  <ErrorIcon /> {error}
                </div>
              ))}
            </>
          ) : (
            ''
          )
        }
        placement="top"
      >
        <div className={clsx(classes.wrapper, disabled && classes.disabled)}>
          <Button
            disabled={disabled}
            className={classes.btn}
            color="primary"
            variant="contained"
            disableElevation
            startIcon={loading ? <CircularProgress className={classes.loader} size={14} /> : <PlayCircleFilledIcon />}
            onClick={() => (loading ? noop : onClickPre())}
            name="generate quote"
          >
            Generate Quote
          </Button>
        </div>
      </Tooltip>
    )
  );
};

export default GetQuoteButton;
